import { Layout } from "antd"
import { FC, Suspense } from "react"
import { Outlet } from "react-router-dom"

const AuthLayout: FC = () => (
  <Layout style={{ minHeight: "100vh", backgroundColor: "#F6F8F9", padding: "0 16px" }}>
    <Suspense>
      <Outlet />
    </Suspense>
  </Layout>
)

export default AuthLayout
