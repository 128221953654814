import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Account",
      "ComponentDataBenefits",
      "ComponentDataEntry",
      "ComponentDataOrder",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Expense",
      "Feedback",
      "HistoryRecord",
      "Home",
      "Layout",
      "Notification",
      "Product",
      "ProductCategory",
      "Purchase",
      "ReactIconsIconlibrary",
      "Sale",
      "Shop",
      "StockStatistic",
      "StorageRecord",
      "Subscription",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "Account",
      "ComponentDataBenefits",
      "ComponentDataEntry",
      "ComponentDataOrder",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiTab",
      "ComponentUiText",
      "Expense",
      "Feedback",
      "HistoryRecord",
      "Home",
      "Layout",
      "Notification",
      "Product",
      "ProductCategory",
      "Purchase",
      "ReactIconsIconlibrary",
      "Sale",
      "Shop",
      "StockStatistic",
      "StorageRecord",
      "Subscription",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const ExpenseFragmentDoc = gql`
    fragment Expense on ExpenseEntity {
  id
  attributes {
    period
    tax
    salary
    rent
    other
    revenue
    grossProfit
    expenses
    preTaxIncome
    netIncome
    sumOfPurchases
  }
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const HistoryRecordFragmentDoc = gql`
    fragment HistoryRecord on HistoryRecordEntity {
  id
  attributes {
    timeStamp
    type
    user {
      data {
        id
        attributes {
          username
        }
      }
    }
    product {
      data {
        id
      }
    }
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
    name
  }
}
    `;
export const StorageRecordFragmentDoc = gql`
    fragment StorageRecord on StorageRecordEntity {
  id
  attributes {
    quantity
    product {
      data {
        id
        attributes {
          name
          sellPrice
          cost
          quantity
          photo {
            data {
              ...File
            }
          }
        }
      }
    }
    shop {
      data {
        id
        attributes {
          name
          account {
            data {
              id
            }
          }
          purchases {
            data {
              id
              attributes {
                status
                order {
                  quantity
                  product {
                    data {
                      id
                    }
                  }
                }
              }
            }
          }
          sales {
            data {
              id
              attributes {
                status
                order {
                  quantity
                  product {
                    data {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on ProductEntity {
  id
  attributes {
    name
    cost
    description
    minLevel
    quantity
    sellPrice
    lowOnStock
    totalSellPrice
    storage_records {
      data {
        ...StorageRecord
      }
    }
    photo {
      data {
        ...File
      }
    }
    shop {
      data {
        id
        attributes {
          name
        }
      }
    }
    product_category {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
}
    `;
export const NotificationFragmentDoc = gql`
    fragment Notification on NotificationEntity {
  id
  attributes {
    message
    unread
    createdAt
    product {
      data {
        ...Product
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const SaleFragmentDoc = gql`
    fragment Sale on SaleEntity {
  id
  attributes {
    customerName
    customerAddress
    customerEmail
    customerPhone
    status
    date
    discount
    adjustment_amount
    paymentType
    saleId
    subtotal
    order {
      id
      quantity
      productPrice
      product {
        data {
          ...Product
        }
      }
    }
    shop {
      data {
        id
        attributes {
          name
        }
      }
    }
    user {
      data {
        id
        attributes {
          username
        }
      }
    }
  }
}
    `;
export const PurchaseFragmentDoc = gql`
    fragment Purchase on PurchaseEntity {
  id
  attributes {
    orderId
    vendor
    date
    status
    email
    subtotal
    address
    discount
    adjustment_amount
    phone
    order {
      quantity
      productPrice
      product {
        data {
          ...Product
        }
      }
    }
    shop {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
}
    `;
export const ShopFragmentDoc = gql`
    fragment Shop on ShopEntity {
  id
  attributes {
    name
    address
    phone
    manager {
      data {
        id
        attributes {
          username
        }
      }
    }
    users {
      data {
        id
        attributes {
          username
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
    products {
      data {
        ...Product
      }
    }
    sales {
      data {
        ...Sale
      }
    }
    purchases {
      data {
        ...Purchase
      }
    }
    account {
      data {
        id
      }
    }
  }
}
    `;
export const SubscriptionFragmentDoc = gql`
    fragment Subscription on SubscriptionEntity {
  id
  attributes {
    name
    description
    cost
    benefit {
      benefit
    }
    icon {
      data {
        ...File
      }
    }
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on UsersPermissionsUserEntity {
  id
  attributes {
    username
    email
    phone
    inactive
    blocked
    subscriptionStartDate
    subscriptionEndDate
    photo {
      data {
        ...File
      }
    }
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    account {
      data {
        id
        attributes {
          businessName
          country
          city
          shops {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
    shop {
      data {
        id
        attributes {
          name
        }
      }
    }
    sales {
      data {
        id
      }
    }
    subscription {
      data {
        attributes {
          name
        }
      }
    }
  }
}
    `;
export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $currentPassword: String!, $passwordConfirmation: String!) {
  changePassword(
    password: $password
    currentPassword: $currentPassword
    passwordConfirmation: $passwordConfirmation
  ) {
    user {
      id
    }
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export const CreateAccountDocument = gql`
    mutation createAccount($input: AccountInput!) {
  createAccount(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export const CreateExpenseDocument = gql`
    mutation createExpense($input: ExpenseInput!) {
  createExpense(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateExpenseMutationFn = Apollo.MutationFunction<CreateExpenseMutation, CreateExpenseMutationVariables>;
export function useCreateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<CreateExpenseMutation, CreateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExpenseMutation, CreateExpenseMutationVariables>(CreateExpenseDocument, options);
      }
export type CreateExpenseMutationHookResult = ReturnType<typeof useCreateExpenseMutation>;
export type CreateExpenseMutationResult = Apollo.MutationResult<CreateExpenseMutation>;
export const CreateHistoryRecordDocument = gql`
    mutation createHistoryRecord($input: HistoryRecordInput!) {
  createHistoryRecord(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateHistoryRecordMutationFn = Apollo.MutationFunction<CreateHistoryRecordMutation, CreateHistoryRecordMutationVariables>;
export function useCreateHistoryRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateHistoryRecordMutation, CreateHistoryRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHistoryRecordMutation, CreateHistoryRecordMutationVariables>(CreateHistoryRecordDocument, options);
      }
export type CreateHistoryRecordMutationHookResult = ReturnType<typeof useCreateHistoryRecordMutation>;
export type CreateHistoryRecordMutationResult = Apollo.MutationResult<CreateHistoryRecordMutation>;
export const CreateProductDocument = gql`
    mutation createProduct($input: ProductInput!) {
  createProduct(data: $input) {
    data {
      id
      attributes {
        quantity
      }
    }
  }
}
    `;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export const CreatePurchaseDocument = gql`
    mutation createPurchase($input: PurchaseInput!) {
  createPurchase(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreatePurchaseMutationFn = Apollo.MutationFunction<CreatePurchaseMutation, CreatePurchaseMutationVariables>;
export function useCreatePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CreatePurchaseMutation, CreatePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePurchaseMutation, CreatePurchaseMutationVariables>(CreatePurchaseDocument, options);
      }
export type CreatePurchaseMutationHookResult = ReturnType<typeof useCreatePurchaseMutation>;
export type CreatePurchaseMutationResult = Apollo.MutationResult<CreatePurchaseMutation>;
export const CreateSaleDocument = gql`
    mutation createSale($input: SaleInput!) {
  createSale(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateSaleMutationFn = Apollo.MutationFunction<CreateSaleMutation, CreateSaleMutationVariables>;
export function useCreateSaleMutation(baseOptions?: Apollo.MutationHookOptions<CreateSaleMutation, CreateSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSaleMutation, CreateSaleMutationVariables>(CreateSaleDocument, options);
      }
export type CreateSaleMutationHookResult = ReturnType<typeof useCreateSaleMutation>;
export type CreateSaleMutationResult = Apollo.MutationResult<CreateSaleMutation>;
export const CreateShopDocument = gql`
    mutation createShop($input: ShopInput!) {
  createShop(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateShopMutationFn = Apollo.MutationFunction<CreateShopMutation, CreateShopMutationVariables>;
export function useCreateShopMutation(baseOptions?: Apollo.MutationHookOptions<CreateShopMutation, CreateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, options);
      }
export type CreateShopMutationHookResult = ReturnType<typeof useCreateShopMutation>;
export type CreateShopMutationResult = Apollo.MutationResult<CreateShopMutation>;
export const CreateStorageRecordDocument = gql`
    mutation createStorageRecord($input: StorageRecordInput!) {
  createStorageRecord(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateStorageRecordMutationFn = Apollo.MutationFunction<CreateStorageRecordMutation, CreateStorageRecordMutationVariables>;
export function useCreateStorageRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateStorageRecordMutation, CreateStorageRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStorageRecordMutation, CreateStorageRecordMutationVariables>(CreateStorageRecordDocument, options);
      }
export type CreateStorageRecordMutationHookResult = ReturnType<typeof useCreateStorageRecordMutation>;
export type CreateStorageRecordMutationResult = Apollo.MutationResult<CreateStorageRecordMutation>;
export const CreateUserDocument = gql`
    mutation createUser($input: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $input) {
    data {
      id
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export const DeleteProductDocument = gql`
    mutation deleteProduct($id: ID!) {
  deleteProduct(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;
export function useDeleteProductMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
      }
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export const DeleteStorageRecordDocument = gql`
    mutation deleteStorageRecord($id: ID!) {
  deleteStorageRecord(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteStorageRecordMutationFn = Apollo.MutationFunction<DeleteStorageRecordMutation, DeleteStorageRecordMutationVariables>;
export function useDeleteStorageRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStorageRecordMutation, DeleteStorageRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStorageRecordMutation, DeleteStorageRecordMutationVariables>(DeleteStorageRecordDocument, options);
      }
export type DeleteStorageRecordMutationHookResult = ReturnType<typeof useDeleteStorageRecordMutation>;
export type DeleteStorageRecordMutationResult = Apollo.MutationResult<DeleteStorageRecordMutation>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPassword(email: $email) {
    __typename
  }
}
    `;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  login(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterInput!) {
  register(input: $input) {
    jwt
    user {
      id
    }
  }
}
    `;
export type RegisterMutationFn = Apollo.MutationFunction<RegisterMutation, RegisterMutationVariables>;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($code: String!, $password: String!, $passwordConfirmation: String!) {
  resetPassword(
    code: $code
    password: $password
    passwordConfirmation: $passwordConfirmation
  ) {
    user {
      email
    }
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export const UpdateAccountDocument = gql`
    mutation updateAccount($id: ID!, $input: AccountInput!) {
  updateAccount(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateAccountMutationFn = Apollo.MutationFunction<UpdateAccountMutation, UpdateAccountMutationVariables>;
export function useUpdateAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAccountMutation, UpdateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAccountMutation, UpdateAccountMutationVariables>(UpdateAccountDocument, options);
      }
export type UpdateAccountMutationHookResult = ReturnType<typeof useUpdateAccountMutation>;
export type UpdateAccountMutationResult = Apollo.MutationResult<UpdateAccountMutation>;
export const UpdateExpenseDocument = gql`
    mutation updateExpense($id: ID!, $input: ExpenseInput!) {
  updateExpense(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateExpenseMutationFn = Apollo.MutationFunction<UpdateExpenseMutation, UpdateExpenseMutationVariables>;
export function useUpdateExpenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExpenseMutation, UpdateExpenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExpenseMutation, UpdateExpenseMutationVariables>(UpdateExpenseDocument, options);
      }
export type UpdateExpenseMutationHookResult = ReturnType<typeof useUpdateExpenseMutation>;
export type UpdateExpenseMutationResult = Apollo.MutationResult<UpdateExpenseMutation>;
export const UpdateNotificationDocument = gql`
    mutation updateNotification($id: ID!, $input: NotificationInput!) {
  updateNotification(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateNotificationMutationFn = Apollo.MutationFunction<UpdateNotificationMutation, UpdateNotificationMutationVariables>;
export function useUpdateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateNotificationMutation, UpdateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateNotificationMutation, UpdateNotificationMutationVariables>(UpdateNotificationDocument, options);
      }
export type UpdateNotificationMutationHookResult = ReturnType<typeof useUpdateNotificationMutation>;
export type UpdateNotificationMutationResult = Apollo.MutationResult<UpdateNotificationMutation>;
export const UpdateProductDocument = gql`
    mutation updateProduct($id: ID!, $input: ProductInput!) {
  updateProduct(id: $id, data: $input) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export const UpdatePurchaseDocument = gql`
    mutation updatePurchase($id: ID!, $input: PurchaseInput!) {
  updatePurchase(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdatePurchaseMutationFn = Apollo.MutationFunction<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>;
export function useUpdatePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePurchaseMutation, UpdatePurchaseMutationVariables>(UpdatePurchaseDocument, options);
      }
export type UpdatePurchaseMutationHookResult = ReturnType<typeof useUpdatePurchaseMutation>;
export type UpdatePurchaseMutationResult = Apollo.MutationResult<UpdatePurchaseMutation>;
export const UpdateSaleDocument = gql`
    mutation updateSale($id: ID!, $input: SaleInput!) {
  updateSale(id: $id, data: $input) {
    data {
      id
    }
  }
}
    `;
export type UpdateSaleMutationFn = Apollo.MutationFunction<UpdateSaleMutation, UpdateSaleMutationVariables>;
export function useUpdateSaleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSaleMutation, UpdateSaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSaleMutation, UpdateSaleMutationVariables>(UpdateSaleDocument, options);
      }
export type UpdateSaleMutationHookResult = ReturnType<typeof useUpdateSaleMutation>;
export type UpdateSaleMutationResult = Apollo.MutationResult<UpdateSaleMutation>;
export const UpdateShopDocument = gql`
    mutation updateShop($id: ID!, $input: ShopInput!) {
  updateShop(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateShopMutationFn = Apollo.MutationFunction<UpdateShopMutation, UpdateShopMutationVariables>;
export function useUpdateShopMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShopMutation, UpdateShopMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShopMutation, UpdateShopMutationVariables>(UpdateShopDocument, options);
      }
export type UpdateShopMutationHookResult = ReturnType<typeof useUpdateShopMutation>;
export type UpdateShopMutationResult = Apollo.MutationResult<UpdateShopMutation>;
export const UpdateStorageRecordDocument = gql`
    mutation updateStorageRecord($id: ID!, $input: StorageRecordInput!) {
  updateStorageRecord(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateStorageRecordMutationFn = Apollo.MutationFunction<UpdateStorageRecordMutation, UpdateStorageRecordMutationVariables>;
export function useUpdateStorageRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStorageRecordMutation, UpdateStorageRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStorageRecordMutation, UpdateStorageRecordMutationVariables>(UpdateStorageRecordDocument, options);
      }
export type UpdateStorageRecordMutationHookResult = ReturnType<typeof useUpdateStorageRecordMutation>;
export type UpdateStorageRecordMutationResult = Apollo.MutationResult<UpdateStorageRecordMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $input: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(data: $input, id: $id) {
    data {
      id
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const ExpenseDocument = gql`
    query expense($id: ID!) {
  expense(id: $id) {
    data {
      ...Expense
    }
  }
}
    ${ExpenseFragmentDoc}`;
export function useExpenseQuery(baseOptions: Apollo.QueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, options);
      }
export function useExpenseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpenseQuery, ExpenseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpenseQuery, ExpenseQueryVariables>(ExpenseDocument, options);
        }
export type ExpenseQueryHookResult = ReturnType<typeof useExpenseQuery>;
export type ExpenseLazyQueryHookResult = ReturnType<typeof useExpenseLazyQuery>;
export type ExpenseQueryResult = Apollo.QueryResult<ExpenseQuery, ExpenseQueryVariables>;
export const ExpensesDocument = gql`
    query expenses($filters: ExpenseFiltersInput) {
  expenses(filters: $filters, sort: ["period:desc"]) {
    data {
      ...Expense
    }
  }
}
    ${ExpenseFragmentDoc}`;
export function useExpensesQuery(baseOptions?: Apollo.QueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
      }
export function useExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExpensesQuery, ExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExpensesQuery, ExpensesQueryVariables>(ExpensesDocument, options);
        }
export type ExpensesQueryHookResult = ReturnType<typeof useExpensesQuery>;
export type ExpensesLazyQueryHookResult = ReturnType<typeof useExpensesLazyQuery>;
export type ExpensesQueryResult = Apollo.QueryResult<ExpensesQuery, ExpensesQueryVariables>;
export const HistoryRecordsDocument = gql`
    query historyRecords($filters: HistoryRecordFiltersInput, $sort: [String], $pagination: PaginationArg) {
  historyRecords(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...HistoryRecord
    }
  }
}
    ${HistoryRecordFragmentDoc}`;
export function useHistoryRecordsQuery(baseOptions?: Apollo.QueryHookOptions<HistoryRecordsQuery, HistoryRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HistoryRecordsQuery, HistoryRecordsQueryVariables>(HistoryRecordsDocument, options);
      }
export function useHistoryRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HistoryRecordsQuery, HistoryRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HistoryRecordsQuery, HistoryRecordsQueryVariables>(HistoryRecordsDocument, options);
        }
export type HistoryRecordsQueryHookResult = ReturnType<typeof useHistoryRecordsQuery>;
export type HistoryRecordsLazyQueryHookResult = ReturnType<typeof useHistoryRecordsLazyQuery>;
export type HistoryRecordsQueryResult = Apollo.QueryResult<HistoryRecordsQuery, HistoryRecordsQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const LayoutDocument = gql`
    query layout {
  layout {
    data {
      attributes {
        headerMenu {
          ...Link
          icon {
            data {
              ...File
            }
          }
        }
        adminMenu {
          ...Link
          icon {
            data {
              ...File
            }
          }
        }
      }
    }
  }
}
    ${LinkFragmentDoc}
${FileFragmentDoc}`;
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
      }
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options);
        }
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>;
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>;
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    id
    username
    email
    phone
    photo {
      data {
        ...File
      }
    }
    role {
      data {
        id
        attributes {
          name
        }
      }
    }
    account {
      data {
        id
        attributes {
          businessName
          country
          city
          shops {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
    shop {
      data {
        id
        attributes {
          name
        }
      }
    }
    sales {
      data {
        id
      }
    }
  }
}
    ${FileFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const NotificationsDocument = gql`
    query notifications($filters: NotificationFiltersInput, $pagination: PaginationArg) {
  notifications(
    filters: $filters
    sort: "createdAt:desc"
    pagination: $pagination
  ) {
    data {
      ...Notification
    }
  }
}
    ${NotificationFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const PreviousExpensesDocument = gql`
    query previousExpenses($filters: ExpenseFiltersInput) {
  expenses(filters: $filters) {
    data {
      id
      attributes {
        grossProfit
        revenue
        expenses
        preTaxIncome
        netIncome
        tax
        sumOfPurchases
      }
    }
  }
}
    `;
export function usePreviousExpensesQuery(baseOptions?: Apollo.QueryHookOptions<PreviousExpensesQuery, PreviousExpensesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreviousExpensesQuery, PreviousExpensesQueryVariables>(PreviousExpensesDocument, options);
      }
export function usePreviousExpensesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreviousExpensesQuery, PreviousExpensesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreviousExpensesQuery, PreviousExpensesQueryVariables>(PreviousExpensesDocument, options);
        }
export type PreviousExpensesQueryHookResult = ReturnType<typeof usePreviousExpensesQuery>;
export type PreviousExpensesLazyQueryHookResult = ReturnType<typeof usePreviousExpensesLazyQuery>;
export type PreviousExpensesQueryResult = Apollo.QueryResult<PreviousExpensesQuery, PreviousExpensesQueryVariables>;
export const ProductDocument = gql`
    query product($id: ID!) {
  product(id: $id) {
    data {
      ...Product
    }
  }
}
    ${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useProductQuery(baseOptions: Apollo.QueryHookOptions<ProductQuery, ProductQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
      }
export function useProductLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductQuery, ProductQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductQuery, ProductQueryVariables>(ProductDocument, options);
        }
export type ProductQueryHookResult = ReturnType<typeof useProductQuery>;
export type ProductLazyQueryHookResult = ReturnType<typeof useProductLazyQuery>;
export type ProductQueryResult = Apollo.QueryResult<ProductQuery, ProductQueryVariables>;
export const ProductCategoriesDocument = gql`
    query productCategories {
  productCategories {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useProductCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
      }
export function useProductCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductCategoriesQuery, ProductCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductCategoriesQuery, ProductCategoriesQueryVariables>(ProductCategoriesDocument, options);
        }
export type ProductCategoriesQueryHookResult = ReturnType<typeof useProductCategoriesQuery>;
export type ProductCategoriesLazyQueryHookResult = ReturnType<typeof useProductCategoriesLazyQuery>;
export type ProductCategoriesQueryResult = Apollo.QueryResult<ProductCategoriesQuery, ProductCategoriesQueryVariables>;
export const ProductsDocument = gql`
    query products($filters: ProductFiltersInput, $sort: [String], $pagination: PaginationArg) {
  products(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...Product
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useProductsQuery(baseOptions?: Apollo.QueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
      }
export function useProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductsQuery, ProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, options);
        }
export type ProductsQueryHookResult = ReturnType<typeof useProductsQuery>;
export type ProductsLazyQueryHookResult = ReturnType<typeof useProductsLazyQuery>;
export type ProductsQueryResult = Apollo.QueryResult<ProductsQuery, ProductsQueryVariables>;
export const ProfitSummaryDocument = gql`
    query profitSummary($input: ProfitSummaryInput!) {
  profitSummary(input: $input) {
    dailyProfit
    dailySales
    periodProfit
    periodSales
    bestSellers {
      title
      value
    }
    topMerchants {
      name
      value
    }
  }
}
    `;
export function useProfitSummaryQuery(baseOptions: Apollo.QueryHookOptions<ProfitSummaryQuery, ProfitSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProfitSummaryQuery, ProfitSummaryQueryVariables>(ProfitSummaryDocument, options);
      }
export function useProfitSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProfitSummaryQuery, ProfitSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProfitSummaryQuery, ProfitSummaryQueryVariables>(ProfitSummaryDocument, options);
        }
export type ProfitSummaryQueryHookResult = ReturnType<typeof useProfitSummaryQuery>;
export type ProfitSummaryLazyQueryHookResult = ReturnType<typeof useProfitSummaryLazyQuery>;
export type ProfitSummaryQueryResult = Apollo.QueryResult<ProfitSummaryQuery, ProfitSummaryQueryVariables>;
export const PurchaseDocument = gql`
    query purchase($id: ID!) {
  purchase(id: $id) {
    data {
      ...Purchase
    }
  }
}
    ${PurchaseFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function usePurchaseQuery(baseOptions: Apollo.QueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, options);
      }
export function usePurchaseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseQuery, PurchaseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseQuery, PurchaseQueryVariables>(PurchaseDocument, options);
        }
export type PurchaseQueryHookResult = ReturnType<typeof usePurchaseQuery>;
export type PurchaseLazyQueryHookResult = ReturnType<typeof usePurchaseLazyQuery>;
export type PurchaseQueryResult = Apollo.QueryResult<PurchaseQuery, PurchaseQueryVariables>;
export const PurchaseIdDocument = gql`
    query purchaseId($input: PurchaseIdInput!) {
  purchaseId(input: $input) {
    purchaseId
  }
}
    `;
export function usePurchaseIdQuery(baseOptions: Apollo.QueryHookOptions<PurchaseIdQuery, PurchaseIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchaseIdQuery, PurchaseIdQueryVariables>(PurchaseIdDocument, options);
      }
export function usePurchaseIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchaseIdQuery, PurchaseIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchaseIdQuery, PurchaseIdQueryVariables>(PurchaseIdDocument, options);
        }
export type PurchaseIdQueryHookResult = ReturnType<typeof usePurchaseIdQuery>;
export type PurchaseIdLazyQueryHookResult = ReturnType<typeof usePurchaseIdLazyQuery>;
export type PurchaseIdQueryResult = Apollo.QueryResult<PurchaseIdQuery, PurchaseIdQueryVariables>;
export const PurchasesDocument = gql`
    query purchases($filters: PurchaseFiltersInput, $sort: [String], $pagination: PaginationArg) {
  purchases(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...Purchase
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${PurchaseFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function usePurchasesQuery(baseOptions?: Apollo.QueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, options);
      }
export function usePurchasesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PurchasesQuery, PurchasesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, options);
        }
export type PurchasesQueryHookResult = ReturnType<typeof usePurchasesQuery>;
export type PurchasesLazyQueryHookResult = ReturnType<typeof usePurchasesLazyQuery>;
export type PurchasesQueryResult = Apollo.QueryResult<PurchasesQuery, PurchasesQueryVariables>;
export const RolesDocument = gql`
    query roles {
  usersPermissionsRoles {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const SaleDocument = gql`
    query sale($id: ID!) {
  sale(id: $id) {
    data {
      ...Sale
    }
  }
}
    ${SaleFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useSaleQuery(baseOptions: Apollo.QueryHookOptions<SaleQuery, SaleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaleQuery, SaleQueryVariables>(SaleDocument, options);
      }
export function useSaleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaleQuery, SaleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaleQuery, SaleQueryVariables>(SaleDocument, options);
        }
export type SaleQueryHookResult = ReturnType<typeof useSaleQuery>;
export type SaleLazyQueryHookResult = ReturnType<typeof useSaleLazyQuery>;
export type SaleQueryResult = Apollo.QueryResult<SaleQuery, SaleQueryVariables>;
export const SaleIdDocument = gql`
    query saleId($input: SaleIdInput!) {
  saleId(input: $input) {
    saleId
  }
}
    `;
export function useSaleIdQuery(baseOptions: Apollo.QueryHookOptions<SaleIdQuery, SaleIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SaleIdQuery, SaleIdQueryVariables>(SaleIdDocument, options);
      }
export function useSaleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SaleIdQuery, SaleIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SaleIdQuery, SaleIdQueryVariables>(SaleIdDocument, options);
        }
export type SaleIdQueryHookResult = ReturnType<typeof useSaleIdQuery>;
export type SaleIdLazyQueryHookResult = ReturnType<typeof useSaleIdLazyQuery>;
export type SaleIdQueryResult = Apollo.QueryResult<SaleIdQuery, SaleIdQueryVariables>;
export const SalesDocument = gql`
    query sales($filters: SaleFiltersInput, $sort: [String], $pagination: PaginationArg) {
  sales(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...Sale
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${SaleFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useSalesQuery(baseOptions?: Apollo.QueryHookOptions<SalesQuery, SalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, options);
      }
export function useSalesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesQuery, SalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, options);
        }
export type SalesQueryHookResult = ReturnType<typeof useSalesQuery>;
export type SalesLazyQueryHookResult = ReturnType<typeof useSalesLazyQuery>;
export type SalesQueryResult = Apollo.QueryResult<SalesQuery, SalesQueryVariables>;
export const ShopDocument = gql`
    query shop($id: ID!) {
  shop(id: $id) {
    data {
      ...Shop
    }
  }
}
    ${ShopFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}
${SaleFragmentDoc}
${PurchaseFragmentDoc}`;
export function useShopQuery(baseOptions: Apollo.QueryHookOptions<ShopQuery, ShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
      }
export function useShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopQuery, ShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopQuery, ShopQueryVariables>(ShopDocument, options);
        }
export type ShopQueryHookResult = ReturnType<typeof useShopQuery>;
export type ShopLazyQueryHookResult = ReturnType<typeof useShopLazyQuery>;
export type ShopQueryResult = Apollo.QueryResult<ShopQuery, ShopQueryVariables>;
export const ShopsDocument = gql`
    query shops($filters: ShopFiltersInput!) {
  shops(filters: $filters) {
    data {
      ...Shop
    }
  }
}
    ${ShopFragmentDoc}
${ProductFragmentDoc}
${StorageRecordFragmentDoc}
${FileFragmentDoc}
${SaleFragmentDoc}
${PurchaseFragmentDoc}`;
export function useShopsQuery(baseOptions: Apollo.QueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
      }
export function useShopsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopsQuery, ShopsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopsQuery, ShopsQueryVariables>(ShopsDocument, options);
        }
export type ShopsQueryHookResult = ReturnType<typeof useShopsQuery>;
export type ShopsLazyQueryHookResult = ReturnType<typeof useShopsLazyQuery>;
export type ShopsQueryResult = Apollo.QueryResult<ShopsQuery, ShopsQueryVariables>;
export const StockReportDocument = gql`
    query stockReport($input: StockReportInput!) {
  stockReport(input: $input) {
    currentStock
    outOfStock
    stockValue
    totalCost
    reOrder
    totalCostMonthAgo
    stockValueMonthAgo
  }
}
    `;
export function useStockReportQuery(baseOptions: Apollo.QueryHookOptions<StockReportQuery, StockReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StockReportQuery, StockReportQueryVariables>(StockReportDocument, options);
      }
export function useStockReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockReportQuery, StockReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StockReportQuery, StockReportQueryVariables>(StockReportDocument, options);
        }
export type StockReportQueryHookResult = ReturnType<typeof useStockReportQuery>;
export type StockReportLazyQueryHookResult = ReturnType<typeof useStockReportLazyQuery>;
export type StockReportQueryResult = Apollo.QueryResult<StockReportQuery, StockReportQueryVariables>;
export const StorageRecordsDocument = gql`
    query storageRecords($filters: StorageRecordFiltersInput, $sort: [String], $pagination: PaginationArg) {
  storageRecords(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...StorageRecord
    }
  }
}
    ${StorageRecordFragmentDoc}
${FileFragmentDoc}`;
export function useStorageRecordsQuery(baseOptions?: Apollo.QueryHookOptions<StorageRecordsQuery, StorageRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StorageRecordsQuery, StorageRecordsQueryVariables>(StorageRecordsDocument, options);
      }
export function useStorageRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StorageRecordsQuery, StorageRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StorageRecordsQuery, StorageRecordsQueryVariables>(StorageRecordsDocument, options);
        }
export type StorageRecordsQueryHookResult = ReturnType<typeof useStorageRecordsQuery>;
export type StorageRecordsLazyQueryHookResult = ReturnType<typeof useStorageRecordsLazyQuery>;
export type StorageRecordsQueryResult = Apollo.QueryResult<StorageRecordsQuery, StorageRecordsQueryVariables>;
export const SubscriptionsDocument = gql`
    query subscriptions {
  subscriptions {
    data {
      ...Subscription
    }
  }
}
    ${SubscriptionFragmentDoc}
${FileFragmentDoc}`;
export function useSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const UserDocument = gql`
    query user($id: ID!) {
  usersPermissionsUser(id: $id) {
    data {
      ...User
    }
  }
}
    ${UserFragmentDoc}
${FileFragmentDoc}`;
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query users($filters: UsersPermissionsUserFiltersInput, $sort: [String], $pagination: PaginationArg) {
  usersPermissionsUsers(filters: $filters, sort: $sort, pagination: $pagination) {
    data {
      ...User
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    ${UserFragmentDoc}
${FileFragmentDoc}`;
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;