import { Space } from "antd"
import { FC } from "react"

import { ReactComponent as LogoImage } from "./Logo.svg"

const Logo: FC = () => {
  return (
    <Space direction={"horizontal"} align={"center"}>
      <LogoImage style={{ height: "auto", width: "100%" }} />
    </Space>
  )
}

export { Logo }
