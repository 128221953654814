import { ThemeConfig } from "antd/es/config-provider"

const theme: ThemeConfig = {
  inherit: false,
  components: {
    Menu: {
      padding: 0,
      colorBorderBg: "unset",
      activeBarHeight: 0,
      colorPrimaryBg: "unset",
    },
    Layout: {
      colorBgLayout: "unset",
      headerBg: "unset",
      bodyBg: "unset",
    },
    // Form: {
    //   verticalLabelMargin: "24px 0 0 0",
    // },
  },
}

export default theme
