import { Badge, Col, Row } from "antd"
import { FC,useEffect,useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { useGeneralContext } from "../../../context/GeneralContext"
import { useNotificationsQuery } from "../../../graphql"
import { useSocket } from "../../../socket/useSocket"

const SidebarLink: FC<{ title: string; imgURL: string | undefined; href: string }> = ({ title, imgURL, href }) => {
  const {pathname} = useLocation()
  const [svg, setSvg] = useState<any>(null)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isErrored, setIsErrored] = useState(false)
  const [unreadNotifications, setUnreadNotifications] = useState<NotificationFragment[]>([])
  const socket = useSocket()
  const {account} = useGeneralContext()
  const { data: notifications, loading, refetch } = useNotificationsQuery({
    variables: { filters: { unread: { eq: true }, account: { id: { eq:account } } } },
  })
  
  useEffect(() => {
    socket.on('notification-created', () => {
      refetch()
    });
    return () => {
      socket.off('notification-created');
      refetch()
    };
  }, [socket, pathname, refetch]);

  useEffect(() => {
    setUnreadNotifications(notifications?.notifications?.data as NotificationFragment[])
  }, [socket, pathname, loading, refetch])

  useEffect(() => {
    fetch(imgURL ?? "")
      .then(res => res.text())
      .then(res => setSvg(res))
      .catch(setIsErrored)
      .then(() => setIsLoaded(true))
  }, [imgURL])

  return (
    <NavLink to={href} style={{ display: "inline-block", paddingLeft: 24 }}>
      {!loading && <Row gutter={10} style={{ width: "100%" }} wrap={false}>
        {(title === "Notifications" && unreadNotifications?.length && unreadNotifications?.length > 0) ? (
          <Col>
            <Badge count={unreadNotifications?.length} dot>
              <div style={{ transform: "translateY(3px)" }} dangerouslySetInnerHTML={{ __html: svg }} />
            </Badge>
          </Col>
        ) : (
          <Col>
            <div style={{ transform: "translateY(3px)" }} dangerouslySetInnerHTML={{ __html: svg }} />
          </Col>
        )}

        <Col flex={1}>
          <div className={"link-title"}>{title}</div>
        </Col>
      </Row>}
    </NavLink>
  )
}

export { SidebarLink }
