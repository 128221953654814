import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from "react"

interface GeneralContextProps {
  children: ReactNode
}

export const GeneralContext = createContext<{
  shop: string
  setShop: Dispatch<SetStateAction<string>>
  account: string
  setAccount: Dispatch<SetStateAction<string>>
  shopOptions: { label: string; value: string }[]
  setShopOptions: Dispatch<SetStateAction<{ label: string; value: string }[]>>
  profile: UsersPermissionsUser | undefined
  setProfile: Dispatch<SetStateAction<UsersPermissionsUser | undefined>>
  isSalesPerson: boolean
  setIsSalesPerson: Dispatch<SetStateAction<boolean>>
  isAdmin: boolean
  setIsAdmin: Dispatch<SetStateAction<boolean>>
}>({
  shop: "",
  setShop: () => null,
  account: "",
  setAccount: () => null,
  shopOptions: [{ label: "", value: "" }],
  setShopOptions: () => null,
  profile: undefined,
  setProfile: () => null,
  isSalesPerson: true,
  setIsSalesPerson: () => null,
  isAdmin: false,
  setIsAdmin: () => null,
})

const GeneralContextProvider: FC<GeneralContextProps> = ({ children }) => {
  const [shop, setShop] = useState<string>("")
  const [account, setAccount] = useState<string>("")
  const [shopOptions, setShopOptions] = useState<{ label: string; value: string }[]>([{ label: "", value: "" }])
  const [profile, setProfile] = useState<UsersPermissionsUser | undefined>(undefined)
  const [isSalesPerson, setIsSalesPerson] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const contextValue = {
    shop,
    setShop,
    account,
    setAccount,
    shopOptions,
    setShopOptions,
    profile,
    setProfile,
    isSalesPerson,
    setIsSalesPerson,
    isAdmin,
    setIsAdmin,
  }

  return <GeneralContext.Provider value={contextValue}>{children}</GeneralContext.Provider>
}

const useGeneralContext = () => useContext(GeneralContext)

export { GeneralContextProvider, useGeneralContext }
